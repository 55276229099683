import React, { createContext, useReducer } from "react";

const initialState = {
  business: {
    natureOfBusiness: [],
    otherNatureOfBusiness: "",
    operation: "",
    under_cac: "",
    sizeOfFarm: "",
    farmPremises: "",
    size_of_team: "",
    is_government_funded: "",
    program_initiative: "",
    storeImageOne: [],
  },
  personal: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    stateId: "",
    lgaId: "",
  },
};
export const MicroLendingContext = createContext<{
  state: initialStateProps;
  dispatch: React.Dispatch<MicroAction>;
}>({
  state: initialState,
  dispatch: () => {},
});

interface PersonalProps {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  gender: string;
  address: string;
  stateId: string;
  lgaId: string;
}

interface BusinessProps {
  natureOfBusiness: string[];
  otherNatureOfBusiness?: string;
  operation: string;
  under_cac: string;
  sizeOfFarm: string;
  farmPremises: string;
  size_of_team: string;
  is_government_funded: string;
  program_initiative?: string;
  storeImageOne?: File[];
}

type initialStateProps = {
  personal: PersonalProps;
  business: BusinessProps;
};
type MicroAction =
  | {
      type: "ADD_PERSONAL";
      payload: PersonalProps;
    }
  | {
      type: "ADD_BUSINESS";
      payload: BusinessProps;
    };

const reducer = (
  state: initialStateProps,
  action: MicroAction
): initialStateProps => {
  switch (action.type) {
    case "ADD_PERSONAL":
      return {
        ...state,
        personal: { ...action.payload },
      };
    case "ADD_BUSINESS":
      return {
        ...state,
        business: { ...action.payload },
      };
    default:
      return state;
  }
};

const MicroLendingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <MicroLendingContext.Provider value={{ state, dispatch }}>
      {children}
    </MicroLendingContext.Provider>
  );
};

export default MicroLendingContextProvider;
