//@ts-nocheck
import React, { useEffect, useState, lazy } from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { Suspense } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Header, Loader, Footer, ErrorPage } from "@gogeepernpm/storybook/lib";
import { useGlobalAuth } from "./hooks/useGlobalAuth";
import { IRootReducerState } from "./redux/IRootReducer";
//@ts-ignore
import AOS from "aos";

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//@ts-ignore

const Homepage = lazy(() => import("./pages/Index"));
const Enroll = lazy(() => import("./pages/Enroll"));

function App() {
  const { isInitialized } = useSelector(
    (state: IRootReducerState) => state.auth
  );

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleItemClick = (index: number) =>
    activeIndex === index ? setActiveIndex(-1) : setActiveIndex(index);
  const toggleSidebar = () => setOpen(!open);

  const contact = () => window.Tawk_API.maximize();
  useEffect(() => {}, [isInitialized]);

  const { triggerLogout, triggerSignUp, triggerlogin } = useGlobalAuth();

  const { isAuthenticated } = useSelector(
    (state: IRootReducerState) => state.auth
  );

  return (
    <div className="App">
      <Suspense fallback={<Loader loading={true} />}>
        <Header
          sidebarOpen={open}
          toggleSidebarOpen={toggleSidebar}
          activeIndex={activeIndex}
          onClick={handleItemClick}
          onTalkToUsClick={contact}
          triggerLogout={triggerLogout}
          isLoggedIn={isAuthenticated}
          triggerLogin={triggerlogin}
          triggerSignup={triggerSignUp}
        />

        <Router>
          <Routes>
            <Route path={"/"} element={<Homepage />} />
            <Route path={"/enroll/:type"} element={<Enroll />} />
            <Route
              path="*"
              element={
                <ErrorPage onContact={contact} onSignUp={triggerSignUp} />
              }
            />
          </Routes>
        </Router>
        <Footer onTalkToUsClick={() => window.Tawk_API.maximize()} />
      </Suspense>
    </div>
  );
}

export default App;
