import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//ts-ignore
import { ChakraProvider } from "@chakra-ui/react";
import { store } from "./redux/store";
import { GlobalAuthProvider } from "./provider";
import MicroLendingContextProvider from "./contexts/MIcroLendingContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const client = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <GlobalAuthProvider>
          <QueryClientProvider client={client}>
            <MicroLendingContextProvider>
              <App />
            </MicroLendingContextProvider>
          </QueryClientProvider>
        </GlobalAuthProvider>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
